export default (url = null, payload = {}) => ({
    loading: false,
    message: null,
    haveError: false,
    success: false,

    handle () {

        this.loading = true;
        this.haveError = false;
        this.success = false;

        axios.post(url, this._payload()).then(response => {

            const {error, message } = response.data

            this.success = !error;
            this.haveError = error;
            this.message = message;

            console.log('_response.message', message)

        }).catch(error => {

            this.haveError = true;
            this.message = error.response.data.message;

            console.error(error.response.data.message)

        }).finally(() => {
            setTimeout(() => {
                this.loading = false
            }, 2000)
        })

    },

    init() {
        this._hydrate();
    },

    _hydrate() {

        //console.log(payload)

        const data = JSON.parse(payload)

        console.log('_hydrate#payload', data)
        console.log('_hydrate#resource-for : url', url)

        for (const key in data) {
            this[key] = data[key]
        }
    },

    _payload() {

        const data = JSON.parse(payload)
        let values = {}
        const that = this

        for (const key in data) {
            values[key] = that[key]
        }

        console.log('_payload#payload', values)

        return values
    },

    _redirectTo() {
        return window.location.reload()
    }
})
