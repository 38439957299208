require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

import resourceEdit from "./resources/edit.js";
import editPlanning from "./app/plannings";
import editObservation from "./app/edit-observation";

Alpine.data('resourceEdit', resourceEdit)
Alpine.data('editResource', resourceEdit)
Alpine.data('editPlanning', editPlanning)
Alpine.data('editObservation', editObservation)

Alpine.start();

(function($) {})(jQuery);
